/*
 * @Author: cxb
 * @Date: 2022-10-18 15:52:03
 * @LastEditors: zry
 * @LastEditTime: 2024-01-08 19:51:33
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2023-12-20 20:34:21
 * @Description: file content
 */

import axios from 'axios';
import config from '@/config';

const { iffApi } = config.ctx;

export const monitorService = {

  // 监控室
  queryJqrjk: () => { return axios.get(`${iffApi}/dp/jqrJks`); },

  // 当日 设备状态信息
  getXfsbByToday: () => { return axios.get(`${iffApi}/dp/indx/xfsbTjByToday`); },

  // 月度 设备状态信息
  getXfsbByMonth: () => { return axios.get(`${iffApi}/dp/indx/xfsbTjByMonth`); },

  // 消防设备
  queryXfsb: (lwdw) => { return axios.get(`${iffApi}/dp/xfsb/${lwdw}`); },

  // 消防维保情况
  queryXfwbqk: (lwdw) => { return axios.get(`${iffApi}/dp/xfwbqk/${lwdw}`); },

  // 建筑物概述
  queryJzw: (lwdw, jzwId) => { return axios.get(`${iffApi}/dp/jzw/${lwdw}/${jzwId || ''}`); },

  // 值班人员
  queryZbry: (lwdw) => { return axios.get(`${iffApi}/dp/jzzb/${lwdw}`); },

  // 火警信息
  queryHjxx: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/${lwdw}`); },

  // 当日告警信息
  getHjxxByToday: (lwdw) => { return axios.get(`${iffApi}/dp/indx/hjxxTjByToday/${lwdw}`); },

  // 统计最近6个月的火警信息
  queryHjxxByMonths: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/listByMonths`); },

  // 统计最近6个月的火警信息
  queryTotalHjxxByMonths: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/totalByMonths`); },

  // 获取是否存在报警情况信息
  getHjxx: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/now/${lwdw}`); },
  // 获取有效报警情况信息
  getYxHjxx: (lwdw) => { return axios.get(`${iffApi}/dp/yxhjxx/now/${lwdw}`); },

  // 月度监控报告统计查询
  queryReport: () => { return axios.get(`${iffApi}/dp/ydjkbg`); },

   // 设备统计情况
  querySbqkTj: () => { return axios.get(`${iffApi}/dp/sbqk/tj`); },

  queryEnvDetail: (params) => { return axios.get(`${iffApi}/env/queryEnvDetail`, { params: params }); },

  getVideoHost: () => { return axios.get(`${iffApi}/video/getVideoHost`); },

  // 统计信息
  queryTjxx: () => { return axios.get(`${iffApi}/dqhz/tjxx`); },

  queryTjxxs: (ssdw) => { return axios.get(`${iffApi}/dqhz/tjxxs/${ssdw}`); },
  // 今日设备状态
  queryDqsbzt: () => { return axios.get(`${iffApi}/dqhz/dqsbzt`); },
  // 获取url
  queryPath: () => { return axios.get(`${iffApi}/dqhz/path`); },
  // 风险实时统计
  queryFxssTj: () => { return axios.get(`${iffApi}/dqhz/tj/fxss/nodw`); },
  // 消防电气火灾设备统计
  queryDqhztj: () => { return axios.get(`${iffApi}/dqhz/dq/dqdqhzsbqk`); },
  // 实时列表数据
  queryYxbgSssj: () => { return axios.get(`${iffApi}/dp/yxbg/sssj`); },
  // 关键一年报警次数(电气火灾)
  queryGjbjcsTj: () => { return axios.get(`${iffApi}/dqhz/tj/gjbjcs`); },
  // 查询所有联网单位的管网压力
  queryAllGwyl: () => { return axios.get(`${iffApi}/dp/all/gwyl`); },
  // 查询今日的受理信息
  queryTodyaSlxx: (ssdw) => { return axios.get(`${iffApi}/dqhz/tj/today/slxx/${ssdw}`); },
  // 消防水设备列
  listXfsSb: () => { return axios.get(`${iffApi}/dp/xfs/sb`); }

};
